import {Request} from "./request";

export default class Lambda extends Request {
    static async get(path, params, auth) {
        return this.makeRequest(path, "get", null, params, auth);
    }

    static async post(path, body, auth) {
        return this.makeRequest(path, "post", body, null, auth);
    }
}

