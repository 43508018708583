// 通过API读取的广告管理模块

import Lambda from "../../controllers/utilities/aws-lambda";
import {getFullPath} from "../../controllers/kaisaku-api";
import React from "react";
import videoPoster from "../../images/videoposter.jpg";
import {Grid, Image} from "semantic-ui-react";

export const environmentMode = ""; // SANDBOX 非18开关

export function getMandatorySANDBOXMode(gameId){
    if (environmentMode === "SANDBOX"){
        return true;
    }else{
        return false;
    }

}

export function ImgVideo(props) {
    const src = props.src;
    const style = props.style;
    if (src.indexOf('.mp4') !== -1) {
        return (
            <div style={style}>
                <video poster={videoPoster} style={{width:'100%',height:'100%',objectFit:'fill',borderRadius: '0px 0px 0px 0px'}} autoPlay="autoPlay" webkit-playsinline='true' playsInline={true} loop="loop" preload="auto" muted="muted">
                    <source src={src} type="video/mp4"></source>
                </video>
            </div>
        );
    }
    return <Image src={src} style={style}/>
}

export function ImgVideoSmall(props) {
    const src = props.src;
    const style = props.style;
    // console.log(src);
    if ((src === undefined) || (src === null)) return <Image />
    if (src.indexOf('.mp4') !== -1) {
        return (
            <div style={style}>
                <video poster={videoPoster} style={{width:'100%',height:'100%',objectFit:'fill'}} autoPlay="autoPlay" loop="loop" preload="auto" muted="muted">
                    <source src={src} type="video/mp4"></source>
                </video>
            </div>
        );
    }
    return <Image src={src} style={style}/>
}

async function getAdinfo(userId, isbreak, isios, gameId) {
    const res = await Lambda.get(getFullPath(`adc/get?swipeMode=1&userId=${userId}&isbreak=${isbreak}&isios=${isios}&noShowGameId=${gameId}`));
    if ((res) && (res.status === 200)) {
        return res.data;
    }else{
        return null;
    }
}

async function getAdinfoByEventId(eventId) {
    const res = await Lambda.get(getFullPath(`adc/get?swipeMode=1&eventId=${eventId}`));
    if ((res) && (res.status === 200)) {
        return res.data;
    }else{
        return null;
    }
}

async function setAdinfo(userId, eventId, sType, index, imageUrl, click, isIo) {
    let response = {
        success: false,
        session: null,
        errorMsgs: []
    };
    const swipeMode = "1";
    const res = await Lambda.post(getFullPath("ad/set"), {
        userId,
        eventId,
        sType,
        swipeMode,
        index,
        imageUrl,
        click,
        isIo
    });
    if ((res) && (res.status === 200)) {
        response.success = true;
    } else {
        response.errorMsgs.push(res.data.message);
    }
    return response;
}

export function ad_pageview(id, ev) {
    const VUE_APP_AD_ANALYTICS_URL = "https://analytics.cityjg.net/DataRecv/ImgPool";
    const formData = new FormData();
    formData.append('Id', id);
    formData.append('Ev', ev);
    try {
      navigator.sendBeacon(VUE_APP_AD_ANALYTICS_URL, formData);
    } catch (reason) {
      console.log("analytics_pageview", reason)
    }
}

export async function setAdItem(id, eventId, sType, index, imageUrl, click, isIo) {
    let ev = 'v';
    if (click === 1){
        ev = 'c';
    }
    return await ad_pageview(id, ev);
    // return await setAdinfo(userId, eventId, sType, index, imageUrl, click, isIo);
}

export async function getAdItemByEventId(eventId) {
    let data = {
        success: false,
        adId:'',
        eventId:'',
        adItem1: {
            adItems:[]
        },
        adItem2: {
            adItems:[]
        },
        adItem3: {
            adItems:[]
        },
        adItem4: {
            adItems:[]
        },
        adImage:'',
        adUrl:'',
        adAImage:'',
        adAUrl:'',
        adBImage:'',
        adBUrl:'',
        adCImage:'',
        adCUrl:''
    }
    const res = await getAdinfoByEventId(eventId);
    console.log("获取数据");
    console.log(res);
    if ((res) && (res.adItem1) && (res.adItem1.adItems)) {
        return res;
    }else{
        return data;
    }
}

export async function getAdItem(userId, isbreak, isios, gameId) {
    let data = {
        success: false,
        adId:'',
        eventId:'',
        adItem1: {
            adItems:[]
        },
        adItem2: {
            adItems:[]
        },
        adItem3: {
            adItems:[]
        },
        adItem4: {
            adItems:[]
        },
        adImage:'',
        adUrl:'',
        adAImage:'',
        adAUrl:'',
        adBImage:'',
        adBUrl:'',
        adCImage:'',
        adCUrl:''
    }
    if (environmentMode === "SANDBOX") return data;
    const res = await getAdinfo(userId, isbreak, isios, gameId);
    console.log("获取数据");
    console.log(res);
    if ((res) && (res.adItem1) && (res.adItem1.adItems)) {
        return res;
    }else{
        return data;
    }
}

async function getAdPData(accessToken, isIOS) {
    const res = await Lambda.get(getFullPath(`adc/getPData?swipeMode=1&accessToken=${accessToken}&isios=${isIOS}`));
    if ((res) && (res.status === 200)) {
        return res.data;
    }else{
        return null;
    }
}

export async function getAdPItem(accessToken, isIOS) {
    let data = {
        success: false,
        adId: '',
        eventId: '',
        adItem1: {
            adItems: []
        },
        adItem2: {
            adItems: []
        },
        adP1Image: '',
        adP1Url: '',
        adP2Image: '',
        adP2Url: ''
    }
    if (environmentMode === "SANDBOX") return data;
    const res = await getAdPData(accessToken, isIOS);
    console.log("获取P区广告数据");
    console.log(res);
    if ((res) && (res.adItem1) && (res.adItem1.adItems)) {
        return res;
    }else{
        return data;
    }
}