import {navigate} from "gatsby";
import {getUA} from "react-device-detect";

export function isSDK() {
    return getUA.includes("JGGSDKWebView");
}

export function ensureSDKEnvironment() {
    if(!isSDK()) {
        if(typeof window !== `undefined`) {
            console.log("This page should not be accessed from a browser!");
            navigate("/");
        }
    }
}

export function rejectIfAnyNull(...params) {
    for(let i = 0; i < params.length; i++) {
        if(!params[i]) {
            if(typeof window !== `undefined`) {
                console.log("This page should not be accessed from a browser!");
                navigate("/");
            }
        }
    }
}