const axios = require("axios").default;
const qs = require("querystring");

export class Request {
    static async makeRequest(path, method, body, queryParams, auth) {
        return axios.request({
            headers: {
                Authorization: auth
            },
            method: method,
            timeout: 30000,
            url: path,
            params: queryParams,
            data: body
        }).catch(e => {
            return e.response;
        });
    }

    static async postForm(path, formData) {
        return axios.post(path, qs.stringify(formData), {
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            transformRequest: (data, headers) => {
                console.log(headers);
                delete headers.common["sec-fetch-mode"];
            }
        });
    }
}