import {getFullPath} from "./kaisaku-api";
import Lambda from "./utilities/aws-lambda";
import cookie from "react-cookies";
import {navigate} from "../../.cache/gatsby-browser-entry";

export default class KaisakuUserApi {
    constructor(session) {
        if(!session) {
            console.log("SESSION_IS_NULL");
        }
        this.session = session;
        this.accessToken = cookie.load("_accessToken");


    }

    getAccessToken(){
        if (this.isValidSession()){
            return this.session.accessToken;
        }else{
            return this.accessToken;
        }
    }

    async getPaymentStatus(currency, price){
        const res = await Lambda.get(getFullPath(`credit-purchase/queryPtStatusForUid?currency=${currency}&price=${price}`), null, this.getAccessToken());
        return res.data;
    }

    async getProfile() {
        return await Lambda.get(getFullPath("getProfile"), null, this.getAccessToken());
    }

    async getProfileApp() {
        return await Lambda.get(getFullPath("getProfile"), { applogin: "1"}, this.getAccessToken());
    }

    async userSignIn() {
        const res = await Lambda.post(getFullPath(`profile/userSignIn`), null, this.session.accessToken);
        if(res.status === 200) {
            return res.data;
        }else {
            return "";
        }
    }

    async getTaskStatus() {
        return await Lambda.get(getFullPath("credit-purchase/transaction/getCreditTaskStatus"), null, this.getAccessToken());
    }

    async getUserVipInfo() {
        return await Lambda.post(getFullPath("vipprofile/getUserVipInfo"), {}, this.getAccessToken());
    }

    async setUserVipShow() {
        return await Lambda.post(getFullPath("vipprofile/setUserVipShow"), {}, this.getAccessToken());
    }

    async getActivityInfo() {
        return await Lambda.post(getFullPath("viplottery/getActivityInfo"), {}, this.getAccessToken());
    }

    async toStartActivity() {
        return await Lambda.post(getFullPath("viplottery/toStartActivity"), {}, this.getAccessToken());
    }

    async getTransactionUrl(gameId, viplv) {
        return await Lambda.post(getFullPath("transaction/getTransactionUrl"), {gameId, viplv}, this.getAccessToken());
    }

    async getTYBUserInfo(isDetal) {
        return await Lambda.get(getFullPath("twoYearBack/getTYBUserInfo"), {isDetal}, this.getAccessToken());
    }

    async postTYBIsCNPlayer(isCN) {
        return await Lambda.post(getFullPath("twoYearBack/postTYBIsCNPlayer"), {isCN: isCN}, this.getAccessToken());
    }

    async postTYBAddress(data) {
        return await Lambda.post(getFullPath("twoYearBack/postTYBAddress"), data, this.getAccessToken());
    }

    async postTYBObj(objId) {
        return await Lambda.post(getFullPath("twoYearBack/postTYBObj"), {objId: objId}, this.getAccessToken());
    }

    async postUnionpayObj(obj) {
        return await Lambda.post(getFullPath("unionpayActivity/postUnionpayObj"), obj, this.getAccessToken());
    }

    async createUpTop() {
        const res = await Lambda.post(getFullPath(`transaction/createZ`), {"gameId":"aac53ce9-f03c-4e71-953b-df9ec022a837","callbackUrl":"http://m.jgg18.me/notice","products":[{"itemId":1,"itemName":"增值","unitPrice":0,"quantity":0,"imageUrl":"http://m.jgg18.me/img/00.png","description":"增值"}]}, this.session.accessToken);
        if(res.status === 200) {
            return res.data;
        }else {
            return "";
        }
    }

    // Transaction
    async getTransaction(transactionId) {
        return await Lambda.get(getFullPath(`transaction/${transactionId}/details`), null, this.getAccessToken());
    }

    async getAllCreditProducts(currency) {
        const res = await Lambda.get(getFullPath("credit-purchase/products"), {currency}, this.getAccessToken());
        return {
            success: res.status === 200,
            ...res.data
        };
    }

    async confirmTransaction(transactionId) {
        return await Lambda.post(getFullPath(`transaction/${transactionId}/confirm`), null, this.getAccessToken());
    }

    // Credit Purchase
    // Takes a transactionId so the player can be returned to the previous confirm-purchase page to carry on spending jgg
    async createTransactionGash(productId, currency, paid, gameId, attributionId, transactionId) {
        const res = await Lambda.post(getFullPath("credit-purchase/transaction/gash/create"), {
            productId,
            currency,
            paid,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
        if(res.status === 200) {
            return res.data;
        }else {
            navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
        }
    }
    async createTransactionP99(productId, currency, paid, gameId, attributionId, transactionId) {
        const res = await Lambda.post(getFullPath("credit-purchase/transaction/p99/create"), {
            productId,
            currency,
            paid,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
        if(res.status === 200) {
            return res.data;
        }else {
            navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
        }
    }
    async createTransactionTwGash(productId, currency, paid, gameId, attributionId, transactionId) {
        const res = await Lambda.post(getFullPath("credit-purchase/transaction/twgash/create"), {
            productId,
            currency,
            paid,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
        if(res.status === 200) {
            return res.data;
        }else {
            navigate(`credit-purchase-result/?success=${false}&message=${res.data.message}`);
        }
    }
    async createTransactionJpay(productId, currency, payType, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/jpay/create"), {
            productId,
            currency,
            payType,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionAsiaunionpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/asia/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionAsiaWechat(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/asiawechat/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHipay(productId, paymentAgency, payType, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/hipay/create"), {
            productId,
            paymentAgency,
            payType,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionSicpay(productId, paymentAgency, payType, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/sicpay/create"), {
            productId,
            paymentAgency,
            payType,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionMtpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/mtpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionBbmslpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/bbmslpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionAntpay2(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/antpay2/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionAntpay3(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/antpay3/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionAntpay4(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/antpay4/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionAntpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/antpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionG3pay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/g3pay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionG3alipay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/g3alipay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionKtpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/ktpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionGhpgpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/ghpg/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionGhpgpayTry3(productId, paymentAgency, gameId, attributionId, transactionId) {
        let dataSuccess = false;
        let res = {};
        for (let i = 0; i < 3; i++) {
            res = await this.createTransactionGhpgpay(productId, paymentAgency, gameId, attributionId, transactionId);
            //检查数据
            if (res.status === 200) {
                console.log(res.data);
                const {resCode, resMsg, response, transactionId} = res.data;
                if (resCode === "000"){
                    let url_qrcode = Buffer.from(response, 'base64').toString('ascii');

                    try{
                        const obj = JSON.parse(url_qrcode);
                        const code = obj.code;
                        if (code === "0000") {
                            dataSuccess = true;
                        }
                    }catch(err) {
                    }
                }
            }
            if (dataSuccess === true){
                console.log("尝试次数 = " + i);
                break;
            }
        }
        return res;
    }

    async createTransactionWechatpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/wechatpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHkalipay2(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/hkalipay2/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionUnipockpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/unipockpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionPaydollar(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/paydollar/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionAllpayx(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/allpayx/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHeepay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/heepay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionGeounionpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/geounionpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionFakapay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/fakapay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionCnalipay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/cnalipay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionCnalipay2(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/cnalipay2/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionCnalipayPrivate(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/cnalipayprivate/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionCnalipayPrivateYi(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/cnalipayprivateyi/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    
    async createTransactionGeoswiftpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/geoswiftpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }
    
    async createTransactionCnalipayPrivateQx(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/cnalipayprivateqx/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionSpglobalpay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/spglobalpay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHkalipay(productId, paymentAgency, gameId, attributionId, transactionId) {
        console.log(productId);
        return await Lambda.post(getFullPath("credit-purchase/transaction/hkalipay/create"), {
            productId,
            paymentAgency,
            gameId,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHupay(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/hahapay/create"), {
            productId,
            gameId,
            paymentAgency,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHupay2(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/hahapay2/create"), {
            productId,
            gameId,
            paymentAgency,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHupay3(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/hahapay3/create"), {
            productId,
            gameId,
            paymentAgency,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHupay4(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/hahapay4/create"), {
            productId,
            gameId,
            paymentAgency,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHupay5(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/hahapay5/create"), {
            productId,
            gameId,
            paymentAgency,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async createTransactionHupay6(productId, paymentAgency, gameId, attributionId, transactionId) {
        return await Lambda.post(getFullPath("credit-purchase/transaction/hahapay6/create"), {
            productId,
            gameId,
            paymentAgency,
            attributionId,
            transactionId,
            isSDK: true
        }, this.session.accessToken);
    }

    async attachEmail(email) {
        let response = {
            success: false,
            data: null,
            errorMsgs: []
        };
        const res = await Lambda.post(getFullPath("attachEmail"), {email}, this.session.accessToken);
        if(res.status === 200) {
            response.success = true;
            response.data = res.data;
        } else {
            response.errorMsgs.push(res.data.message);
        }

        return response;
    }

    async gatherTransactionMeta(transactionId) {
        const accessToken = this.session ? this.session.accessToken : undefined;
        const res = await Lambda.get(getFullPath("gatherCustomerServiceMeta"), {transactionId}, accessToken);
        return res.data;
    }

    isValidSession() {
        return !!this.session && !!this.session.accessToken;
    }
}