import cookie from 'react-cookies';

export default class AndroidComm {
    static sendSession(session) {
        if (session && session.userId && session.accessToken){
            this.sendRawMessage(session);
        }else{
            this.sendRawMessage({
                success: false,
                message: "Internal Server Error"
            });
        }
    }

    static sendPurchaseResult(result) {
        if(result.status === 200) {
            this.sendRawMessage(result.data);
        } else {
            this.sendRawMessage({
                success: false,
                message: result.data.message
            });
        }
    }

    static sendRawMessage(message) {
        if (!cookie.load("jggsdk_sendRawMessage")){
            console.log(cookie.load("jggsdk_sendRawMessage"));
            cookie.save("jggsdk_sendRawMessage", new Date().getTime() + 2000, {
                path: "/",
                expires: new Date(new Date().getTime() + 2000) 
            });

        let platform_operation = cookie.load('_platform_operation');
        if (!platform_operation){
            platform_operation = "pay";
        }
        if ((platform_operation === "login") || (platform_operation === "pay")){
            let operation = platform_operation + "_complete";
            this.postH5Message(operation, message);
        }else{
            const path = `jggsdk:${JSON.stringify(message)}`;
            console.log("Sending message: " + path);
            window.open(path, "_self");
        }
        }else{
            console.log("waiting");
        }
    }

    static postH5Message(operation, jsondata){
        const data={
            "operation": operation,
            "params": {}
          };
        data.params.jggsdk = jsondata;  
        console.log("Sending message: " , data);
        window.top.postMessage(data,'*');
    }
}

export class TransactionResult {
    success;
    transactionId;
    status;
    message;

    constructor(success, transactionId, status, message) {
        this.success = success;
        this.transactionId = transactionId;
        this.status = status;
        this.message = message;
    }
}