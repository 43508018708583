import i18next from "i18next";
import Lambda from "./utilities/aws-lambda";

const urlJoin = require("url-join");

export const getFullPath = endpoint => {
    return urlJoin(process.env.endpoint, endpoint);
};

class KaisakuApi {
    static async loginJ4(identifier, password, attributionId, gameId, platform) {
        let response = {
            success: false,
            session: null,
            errorMsgs: []
        };

        let correctFormat = true;
        if(!password) {
            correctFormat = false;
            response.errorMsgs.push("Password cannot be empty!");
        }

        if(!identifier) {
            correctFormat = false;
            response.errorMsgs.push("Username/e-mail cannot be empty!");
        }

        if(correctFormat) {
            const res = await Lambda.post(getFullPath("authenticateJ4"), {
                identifier,
                password,
                attributionId,
                platform
            });
            if(res.status === 200) {
                response.success = true;
                response.session = res.data;
            } else {
                response.errorMsgs.push(res.data.message);
            }
        }

        return response;
    }

    static async login(identifier, password, attributionId, gameId) {
        let response = {
            success: false,
            session: null,
            errorMsgs: []
        };

        let correctFormat = true;
        if(!password) {
            correctFormat = false;
            response.errorMsgs.push(i18next.t("SDK.Incorrectinformationentered"));
            return response;
        }

        if(!identifier) {
            correctFormat = false;
            response.errorMsgs.push(i18next.t("SDK.Incorrectinformationentered"));
        }

        if(correctFormat) {
            const res = await Lambda.post(getFullPath("authenticate"), {
                identifier,
                password,
                attributionId,
                gameId,
                isSDK: true
            });
            if(res.status === 200) {
                response.success = true;
                response.session = res.data;
            } else {
                response.errorMsgs.push(res.data.message);
            }
        }

        return response;
    }

    static async signup(username, password, confirmPwd, attributionId, gameId) {
        let response = {
            success: false,
            session: null,
            errorMsgs: []
        };

        let correctFormat = true;
        if(!password) {
            correctFormat = false;
            response.errorMsgs.push(i18next.t("SDK.Incorrectinformationentered"));
            return response;
        }

        if(password !== confirmPwd) {
            correctFormat = false;
            response.errorMsgs.push(i18next.t("SDK.Incorrectinformationentered"));
            return response;
        }

        if(!username) {
            correctFormat = false;
            response.errorMsgs.push(i18next.t("SDK.Incorrectinformationentered"));
            return response;
        }

        if (!validatePassWd(password)){
            correctFormat = false;
            response.errorMsgs.push(i18next.t("SDK.Passwordcanonly"));
            return response;
        }

        if(correctFormat) {
            const res = await Lambda.post(getFullPath("createAccount"), {
                username,
                password,
                attributionId,
                gameId,
                isSDK: true
            });
            if(res.status === 200) {
                response.success = true;
                response.session = res.data;
            } else {
                response.errorMsgs.push(res.data.message);
            }
        }

        return response;
    }

    static async quickSignUp(attributionId, gameId, captchaid, captchacode) {
        let response = {
            success: false,
            session: null,
            errorMsgs: []
        };

        const res = await Lambda.post(getFullPath("quickSignUp"), {
            attributionId,
            gameId,
            captchaid,
            captchacode,
            isSDK: true
        });
        if(res.status === 200) {
            response.success = true;
            response.session = res.data;
        } else {
            response.errorMsgs.push(res.data.message);
        }
        return response;
    }

    static async quickSignUpCaptcha(attributionId, gameId) {
        let response = {
            success: false,
            session: null,
            errorMsgs: []
        };

        const res = await Lambda.post(getFullPath("quickSignUpCaptcha"), {
            attributionId,
            gameId,
            isSDK: true
        });
        if(res.status === 200) {
            response.success = true;
            response.data = res.data;
        } else {
            response.errorMsgs.push(res.data.message);
        }
        return response;
    }

    static async refreshSession(token, attributionId, gameId) {
        let response = {
            success: false,
            session: null,
            errorMsgs: []
        };

        const res = await Lambda.post(getFullPath("refreshAccess"), {
            ...token,
            attributionId,
            gameId,
            isSDK: true
        });
        if(res.status === 200) {
            response.success = true;
            response.session = res.data;
        } else {
            response.errorMsgs.push(res.data.message);
        }

        return response;
    }

    static async getMessageStatus(gameId, accessToken){
        const res = await Lambda.get(getFullPath(`getSdkConfigs/${gameId}`), null, accessToken);
        return res.data;
    }
    static async getCreditProduct(productId, accessToken) {
        const res = await Lambda.get(getFullPath(`credit-purchase/products/${productId}`), null, accessToken);
        return res.data;
    }

    static async getTaskStatus2(accessToken) {
        return await Lambda.get(getFullPath("credit-purchase/transaction/getCreditTaskStatus"), null, accessToken);
    }

    static async getUserVipInfo2(accessToken) {
        return Lambda.post(getFullPath("vipprofile/getUserVipInfo"), {}, accessToken);
    }

    static async setUserVipShow2(accessToken) {
        return Lambda.post(getFullPath("vipprofile/setUserVipShow"), {}, accessToken);
    }

    static async getActivityInfo2(accessToken) {
        return Lambda.post(getFullPath("viplottery/getActivityInfo"), {}, accessToken);
    }

    static async toStartActivity2(accessToken) {
        return Lambda.post(getFullPath("viplottery/toStartActivity"), {}, accessToken);
    }

    static async getTransactionUrl2(gameId, viplv, accessToken) {
        return Lambda.post(getFullPath("transaction/getTransactionUrl"), {gameId, viplv}, accessToken);
    }

    static async getTYBUserInfo2(isDetal, accessToken) {
        return await Lambda.get(getFullPath("twoYearBack/getTYBUserInfo"), {isDetal}, accessToken);
    }

    static async setUrlToTemp(url, accessToken) {
        return await Lambda.post(getFullPath("activityApi/setUrlToTemp"), { url }, accessToken);
    }
    
    static async postTYBIsCNPlayer2(isCN, accessToken) {
        return await Lambda.post(getFullPath("twoYearBack/postTYBIsCNPlayer"), {isCN: isCN}, accessToken);
    }

    static async postTYBAddress2(data, accessToken) {
        return await Lambda.post(getFullPath("twoYearBack/postTYBAddress"), data, accessToken);
    }

    static async postTYBObj2(objId, accessToken) {
        return await Lambda.post(getFullPath("twoYearBack/postTYBObj"), {objId: objId}, accessToken);
    }

    static async postUnionpayObj2(obj, accessToken) {
        return await Lambda.post(getFullPath("unionpayActivity/postUnionpayObj"), obj, accessToken);
    }

    static async getHkaliPay2TransactionStatus(transactionId) {
        const res = await Lambda.get(getFullPath(`credit-purchase/transaction/${transactionId}/status`));
        if(res.status === 200) {
            return {
                transaction: res.data
            };
        } else {
            return res.data;
        }
    }

    static async getWechatPayTransactionStatus(transactionId) {
        const res = await Lambda.get(getFullPath(`credit-purchase/wechattransaction/${transactionId}/status`));
        if(res.status === 200) {
            return {
                transaction: res.data
            };
        } else {
            return res.data;
        }
    }

    static async getCreditTransactionStatus(transactionId) {
        const res = await Lambda.get(getFullPath(`credit-purchase/transaction/${transactionId}/status`));
        if(res.status === 200) {
            return {
                transaction: res.data
            };
        } else {
            return res.data;
        }
    }

    static async getCreditTransactionDetails(transactionId) {
        const res = await Lambda.get(getFullPath(`credit-purchase/transaction/${transactionId}/details`));
        if(res.status === 200) {
            return {
                transaction: res.data
            };
        } else {
            return res.data;
        }
    }

    static async getPaymentButtonData(){
        return await Lambda.get(getFullPath(`credit-purchase/getPtButtonData`));
    }

    static async getLastTransaction(accessToken) {
        const res = await Lambda.post(getFullPath(`credit-purchase/transaction/getLast`),null,accessToken);
        if(res.status === 200) {
            return {
                data: res.data
            };
        } else {
            return res.data;
        }
    }

    static async getCardInfo(accessToken) {
        const res = await Lambda.get(getFullPath(`card/get`),null,accessToken);
        if(res.status === 200) {
            return {
                data: res.data
            };
        } else {
            return undefined;
        }
    }

    static async getQueryTransaction(accessToken) {
        const res = await Lambda.post(getFullPath(`credit-purchase/transaction/getQuery`),null, accessToken);
        if(res.status === 200) {
            return {
                data: res.data
            };
        } else {
            return res.data;
        }
    }

    static async setShowQueryTransaction(transactionId, accessToken) {
        const res = await Lambda.post(getFullPath(`credit-purchase/transaction/setShowQuery`), {transactionId: transactionId}, accessToken);
        if(res.status === 200) {
            return {
                data: res.data
            };
        } else {
            return res.data;
        }
    }

    static async getNoodleInfoV(gameId, accessToken) {
        const res = await Lambda.get(getFullPath(`credit-purchase/showNoodleV?gameId=${gameId}`), null, accessToken);
        if(res.status === 200) {
            return {
                data: res.data
            };
        } else {
            return res.data;
        }
    }

    static async getNoodleInfo(gameId, accessToken){
        const res = await Lambda.get(getFullPath(`credit-purchase/showNoodle?gameId=${gameId}`), null, accessToken);
        if(res.status === 200) {
            return {
                data: res.data
            };
        } else {
            return res.data;
        }
    }


    static async getInfoForM1(accessToken) {
        const res = await Lambda.get(getFullPath("item/get2204"), null, accessToken);
        return {
            success: res.status === 200,
            ...res.data
        };
    }

    static async getWalletForM1(accessToken) {
        const res = await Lambda.get(getFullPath("transaction/wallet"), null, accessToken);
        return {
            success: res.status === 200,
            ...res.data
        };
    }
    
    static async confirmTransactionForM1(body, accessToken) {
        return await Lambda.post(getFullPath(`item/confirmTransaction2204`), body, accessToken);
    }

    static async createTransactionForM1(accessToken) {
        return await Lambda.post(getFullPath(`item/createTransaction2204`), null, accessToken);
    }

    static async setReadStatus(body, accessToken) {
        return await Lambda.post(getFullPath(`item/setStatus2204`), body, accessToken);
    }



    static async resetPassword(username) {
        let response = {
            success: false,
            data: null,
            errorMsgs: []
        };
        const res = await Lambda.post(getFullPath("forgotPassword"), {identifier: username});
        if(res.status === 200) {
            response.success = true;
            response.data = res.data;
        } else {
            response.errorMsgs.push(res.data.message);
        }

        return response;
    }

    static async confirmEmail(userId, secret) {
        const res = await Lambda.post(getFullPath("confirmEmail"), {userId, secret});
        return {
            success: res.status === 200,
            ...res.data
        };
    }

    static async resetPasswordConfirm(userId, secret, password, confirmPwd) {
        let response = {
            success: false,
            session: null,
            errorMsgs: []
        };

        let correctFormat = true;
        if(!password) {
            correctFormat = false;
            response.errorMsgs.push("Passwords cannot be empty!");
        }

        if(password !== confirmPwd) {
            correctFormat = false;
            response.errorMsgs.push("Passwords don't match!");
        }

        if(correctFormat) {
            const res = await Lambda.post(getFullPath("confirmResetPassword"), {
                userId,
                secret,
                password
            });
            if(res.status === 200) {
                response.success = true;
                response.session = res.data;
            } else {
                response.errorMsgs.push(res.data.message);
            }
        }
        return response;
    }

    static async getActiveEvents() {
        const res = await Lambda.get(getFullPath("credit-purchase/events/current"));
        if(res.status === 200) {
            return res.data.events;
        } else {
            return [];
        }
    }
}

export default KaisakuApi;


function validatePassWd(password) {
    const str = password;
    if (str == null || str.length < 8 || str.length > 16) {
        return false;
    }
    const reg1 = new RegExp(/^[0-9A-Za-z]+$/);
    if (!reg1.test(str)) {
        return false;
    }
    return getPasswordErrors(password);
    // const reg = new RegExp(/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/);
    // if (reg.test(str)) {
    //     return true;
    // } else {
    //     return false;
    // }
}


function getPasswordErrors(password) {
    if (startsWithSpace(password)) {
        return false;
    }
    if (!sixCharsOrMore(password)) {
        return false;
    }
    if (!containsOnlyLegalChars(password)) {
        return false;
    }
    return true;
}

function startsWithSpace(str) {
    const startsWithSpace = /^ /g;
    return startsWithSpace.test(str);
}

function sixCharsOrMore(str) {
    const sixCharsOrMore = /.{6,}/g;
    return sixCharsOrMore.test(str);
}

function containsOnlyLegalChars(str) {
    const containsOnlyLegalChars = /^(?=.*[A-Za-z\d @$!%*#?&^+])[A-Za-z\d @$!%*#?&^+]*$/g;
    return containsOnlyLegalChars.test(str);
}
